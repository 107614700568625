import React from "react";
import StandardSectionHeader from "../assets/elements/StandardSectionHeader";
import * as PropTypes from "prop-types";
import HomeNavigation, { HIGHLIGHT_SECTION } from "./HomeNavigation";

import VideoAnnotateAndManage from "../assets/images/annotate-automate-manage-evaluate-small.mp4";
import VideoComponent from "../components/VideoComponent";

HomeNavigation.propTypes = { scroll: PropTypes.func };

const FeaturesHome = () => {
  return (
    <section className="relative">
      <div
        className="absolute inset-0 mb-0 pointer-events-none"
        aria-hidden="true"
      />
      <div className="absolute left-0 right-0 w-px h-20 p-px m-auto transform -translate-y-1/2 bg-gray-200" />

      <div className="relative max-w-6xl px-4 mx-auto sm:px-6">
        <div className="pt-12 pb-20 md:pt-20 md:pb-20">
          {/* Categories */}
          <div className="mb-12 md:mb-12">
            <HomeNavigation highlight={HIGHLIGHT_SECTION.HOW_IT_WORKS} />
          </div>

          <StandardSectionHeader
            title={"How it works"}
            header={"Unlocking data-centric AI"}
            description={
              "Encord's mission is to enable every company to unlock the power of AI. We enable world-leading computer vision teams to rapidly build and continuously improve their models."
            }
          />

          <VideoComponent
            width="100%"
            autoPlay={true}
            preload="auto"
            controls={false}
            loop
            muted
          >
            <source src={VideoAnnotateAndManage} type="video/mp4" />
          </VideoComponent>
        </div>
      </div>
    </section>
  );
};

export default FeaturesHome;
