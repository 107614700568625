import React from "react";

import ImageMicroModelComparison from "../assets/images/micro-models-raster-text-small.svg";

import CompetitorTable from "../assets/elements/CompetitorComparisonTabe";
import FeaturesHome from "../partials/FeaturesHome";
import RightColours from "../utilities/RightColours";
import LeftColours from "../utilities/LeftColours";
import TwoFeatureOverview from "../assets/elements/TwoFeatureOverview";
import { HIGHLIGHT_SECTION } from "../partials/HomeNavigation";
import Cta from "../partials/Cta";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

import LogoCloud from "../assets/elements/LogoCloud";
import LeadFormWithAPI from "../components/LeadFormWithAPI";

const Home = ({ location }) => {
  return (
    <Layout location={location}>
      {/*  Page illustration */}
      <LeftColours />
      <RightColours />

      <div id="home" />

      {/*  Page sections */}
      <div className="py-60 md">
        <div className="flex flex-col justify-center items-center pb-10">
          <h1 className="relative h1 lg:text-5xl mb-4 font-red-hat-display font-extrabold z-1">
            {" "}
            Encord vs CVAT
          </h1>
          <p className="relative text-xl text-gray-600 dark:text-gray-400 pb-6 max-w-[66%] text-center">
            {" "}
            Create training data 6x faster, manage data operations more
            efficiently and accelerate model development. Try Encord for free
            today.
          </p>
          <LeadFormWithAPI
            pageName={"CVAT Comparison Page"}
            location={location}
          />
        </div>

        <div className="flex justify-center items-center">
          <CompetitorTable
            competitorLogo={
              "https://assets-global.website-files.com/5d7b77b063a9066d83e1209c/615c491e5385b66f264921b3_cvat.png"
            }
          />
        </div>
      </div>

      <LogoCloud
        title={"Trusted by pioneering AI teams"}
        pb={10}
        pbMd={10}
        numImages={6}
        imageOne={
          <img
            src={
              "https://images.prismic.io/encord/1716e248-7485-43e9-9454-bb17670cd7d4_stanford-medicine-logo-vector.png?auto=compress,format"
            }
            alt={"Stanford Medicine Logo"}
            className={"h-24 w-32"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageTwo={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/8ed83228-e834-494f-84d4-06f0b698c5d6_logo_uhn.svg"
            }
            alt={"UHN logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageThree={
          <img
            src={
              "https://images.prismic.io/encord/bbe742ba-2b6e-4f9a-894e-1110b46e0daa_logo_royal_navy.webp?auto=compress,format"
            }
            alt={"royal navy logo"}
            className={"h-24 w-20"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFour={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/dca52008-c02a-4751-af64-181d69d82def_veo-landing-page.svg"
            }
            alt={"VEO logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageFive={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/aa834409-1594-49f1-872e-d6b0ec856626_NHS_gstt.svg"
            }
            alt={"St Thomas logo"}
            className={"h-24 w-36 lg:w-40"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
        imageSix={
          <img
            src={
              "https://encord.cdn.prismic.io/encord/f3a7d750-8fcf-4a89-9212-59a427503a98_klc.svg"
            }
            alt={"KCL logo"}
            className={"h-24 w-24"}
            loading="lazy"
            width={0}
            height={0}
          />
        }
      />

      <div id="modus" />

      <FeaturesHome />

      <div id="annotate" />

      {/* Annotate */}
      <TwoFeatureOverview
        highlight={HIGHLIGHT_SECTION.ANNOTATE}
        includeHeader={true}
        includeTopPadding={true}
        includeTopBorder={true}
        includeGrayTransition={false}
        title={"Annotate"}
        header={"The computer vision-first toolkit"}
        description={
          "Label any computer vision modality - all in one platform."
        }
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/uses-platform.png"}
            width={900}
            alt={"Uses platform"}
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/platform-dicom-cropped-right.png"}
            width={900}
            alt={"Uses platform"}
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../assets/images/platform-sar.png"}
            width={900}
            alt={"Uses platform"}
          />
        }
        headerOne={"Image & video"}
        headerTwo={"Medical imagery"}
        headerThree={"Synthetic-aperture radar"}
        descriptionOne={
          "Our fast and intuitive collaborative annotation tools enrich your data so that you can build cutting-edge AI applications. Encord automatically classifies, detects, segments, and tracks objects in image & video."
        }
        descriptionTwo={
          "Our medical image annotation tools allow you to annotate all common image modalities - including 3D such as CT, X-ray, and MRI. Encord supports coronal, sagittal, and axial views."
        }
        descriptionThree={
          "Easy handling of synthetic-aperture radar (SAR) in various modes for computer vision applications."
        }
        learnMoreOne={"/video/"}
        learnMoreTwo={"/dicom/"}
        learnMoreThree={"/sar/"}
        learnMoreOneText={"Learn about image & video annotation"}
        learnMoreTwoText={"Learn about DICOM annotation"}
        learnMoreThreeText={"Learn about SAR annotation"}
      />

      <div id="automate" />

      <TwoFeatureOverview
        highlight={HIGHLIGHT_SECTION.AUTOMATE}
        includeHeader={true}
        includeTopPadding={true}
        includeGrayTransition={true}
        title={"Automate"}
        header={"Harness the power of AI-assisted labeling"}
        description={
          "Micro-models: A novel approach to model-assisted labeling. Save time and improve label quality by reducing manual work and augment your experts with AI."
        }
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/feature-encord-model-import.png"}
            width={500}
            alt={"Png feature model import"}
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/download-models.png"}
            width={700}
            alt={"Downloading models"}
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../assets/images/flower-segmentation.png"}
            width={900}
            alt={"Flower segmentation example"}
          />
        }
        headerOne={"Active learning pipelines"}
        headerTwo={"Download & deploy"}
        headerThree={"Native automation"}
        descriptionOne={
          "Integrate micro-models into existing data pipelines with our APIs and SDK. Combine your production model and micro-models with ease."
        }
        descriptionTwo={
          "Download micro-models for integration with downstream applications with the click of a button. Deployable in secure environments and on edge."
        }
        descriptionThree={
          "Augment your workforce with our novel micro-model technology, flexible labeling tools, and automation functions such as object tracking, interpolation, and auto-segmentation."
        }
        learnMoreOne={"/api/"}
        learnMoreTwo={"/automate/"}
        learnMoreThree={"/automate/"}
        learnMoreOneText={"Learn about data pipelines"}
        learnMoreTwoText={"Learn about micro-models"}
        learnMoreThreeText={"Learn about labeling automation"}
      />

      <ImageMicroModelComparison
        className="max-w-full rounded-md mx-auto md:max-w-none h-auto"
        width={1100}
      />

      <br />
      <br />

      <div id="evaluate" />

      <TwoFeatureOverview
        highlight={HIGHLIGHT_SECTION.EVALUATE}
        includeHeader={true}
        includeTopPadding={true}
        includeTopBorder={true}
        includeGrayTransition={false}
        title={"Evaluate"}
        header={"Automation to assess your data quality"}
        description={
          "Encord offers intelligent data evaluation features, ensuring that your labels are of the highest possible quality before they go into production."
        }
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/quality-pred-iou-grid-in-program.png"}
            width={550}
            alt={"Quality prediction iou grid in program"}
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/feature-encord-model-import.png"}
            width={500}
            alt={"Png feature model import"}
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../assets/images/review-json.png"}
            width={700}
            alt={"Png review json"}
          />
        }
        headerOne={"Predict label quality"}
        headerTwo={"Model validation"}
        headerThree={"Expert review"}
        descriptionOne={
          "Use our automated quality control features to ensure only the best ground truth is delivered to your models."
        }
        descriptionTwo={
          "Import model predictions through our APIs and SDK to find errors and biases in your model."
        }
        descriptionThree={
          "Encord supports purpose-built workflows for domain experts to guarantee quality control & compliance."
        }
        learnMoreOne={"/quality/"}
        learnMoreTwo={"/api/"}
        learnMoreThree={"/data-operations/"}
        learnMoreOneText={"Learn about automated data quality assessment"}
        learnMoreTwoText={"Learn about model validation pipelines"}
        learnMoreThreeText={"Learn about data operations"}
      />

      <br />
      <br />

      <div id="manage" />

      <TwoFeatureOverview
        highlight={HIGHLIGHT_SECTION.MANAGE}
        includeHeader={true}
        includeTopBorder={true}
        includeTopPadding={true}
        includeGrayTransition={true}
        title={"Manage"}
        header={"The operating system for your training data"}
        description={
          "Centralise all of your label & data operations in one scalable platform."
        }
        imageOne={
          <StaticImage
            loading="lazy"
            src={"../assets/images/bucket-integration.png"}
            width={500}
            alt={"Cloud bucket integration"}
          />
        }
        imageTwo={
          <StaticImage
            loading="lazy"
            src={"../assets/images/feature-labeling-algo.png"}
            width={500}
            alt={"Feature labeling algorithm"}
          />
        }
        imageThree={
          <StaticImage
            loading="lazy"
            src={"../assets/images/branch-project.png"}
            width={500}
            alt={"Branch project"}
          />
        }
        headerOne={"Customise data pipelines"}
        headerTwo={"Modular automation"}
        headerThree={"Version your labels"}
        descriptionOne={
          "Our APIs & SDK allow for easy building of workflow scripts to converge quickly into successful data strategies. Set up complex pipelines and integrations within minutes."
        }
        descriptionTwo={
          "Compose labeling strategies with image clustering, classical computer vision algorithms, and state of the art deep learning techniques."
        }
        descriptionThree={
          "Our labelset management capabilities allow you to experiment fluidly with versioned data and labels."
        }
        learnMoreOne={"/api/"}
        learnMoreTwo={"/automate/"}
        learnMoreThree={"/machine-learning/"}
        learnMoreOneText={"Learn about data integrations"}
        learnMoreTwoText={"Learn about labeling automation"}
        learnMoreThreeText={"Learn about labelset operations"}
      />

      <Cta
        location={location}
        pageName={"CVAT Comparison Page"}
        ctaText={"Talk to sales"}
      />
    </Layout>
  );
};

export default Home;

export const Head = () => <SEO />;
