import React from "react";
import Tick from "../icons/tick.svg";
import Cross from "../icons/cross.svg";
import EncordLogo from "../images/EncordLogo.svg";

const CompetitorTable = ({ competitorLogo }) => {
  return (
    <div className="flex flex-col shadow-xl	rounded-lg	md:p-16 min-w-[66%]">
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden">
            <table className=" table-auto min-w-[100%] ">
              <thead className="border-b">
                <tr>
                  <th
                    scope="col"
                    className="font-semibold text-lg px-6 py-4 text-left "
                  >
                    Feature
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4"
                  >
                    <EncordLogo className="block m-auto max-w-8 max-h-8" />
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4"
                  >
                    <img
                      width={0}
                      height={0}
                      src={competitorLogo}
                      className="block m-auto max-h-8 w-auto"
                      alt="Competitor Logo"
                      loading="lazy"
                    />
                    {/* <StaticImage
                     src={competitorLogo}
                     className="block m-auto max-w-8 max-h-8"
                     alt="Competitor Logo"
                     loading="lazy"
                    /> */}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-b">
                  <td className="px-6 sm:px-2 py-4 text-lg font-medium text-gray-900 sm:max-w-[150px]">
                    Infinite nesting of ontology classifications
                  </td>
                  <td className="text-sm text-gray-900 font-light lg:px-10 py-4 whitespace-nowrap text-center">
                    <Tick className="block m-auto" />
                  </td>
                  <td className="text-sm text-gray-900 font-light px-10 py-4 whitespace-nowrap text-center">
                    <Cross className="block m-auto" />
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td className="px-6 sm:px-2 py-4 text-lg font-medium text-gray-900 sm:max-w-[150px]">
                    Built-in model debugging
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Tick className="block m-auto" />
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Cross className="block m-auto" />
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td className="px-6 sm:px-2 py-4 text-lg font-medium text-gray-900 sm:max-w-[150px]">
                    Native DICOM interface
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Tick className="block m-auto" />
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Cross className="block m-auto" />
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td className="px-6 sm:px-2 py-4 text-lg font-medium text-gray-900 sm:max-w-[150px] ">
                    Simple transparent pricing
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Tick className="block m-auto" />
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Cross className="block m-auto" />
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td className="px-6 sm:px-2 py-4 text-lg font-medium text-gray-900 sm:max-w-[150px] ">
                    Micro Model Annotation Automation
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Tick className="block m-auto" />
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Cross className="block m-auto" />
                  </td>
                </tr>
                <tr className="bg-white border-b">
                  <td className="px-6 sm:px-2 py-4 text-lg font-medium text-gray-900 sm:max-w-[150px] ">
                    Dedicated data engineer support
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Tick className="block m-auto" />
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap text-center">
                    <Cross className="block m-auto" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompetitorTable;
